import Link from "next/link";
import { usePathname } from "next/navigation";

import React, { FC } from "react";

import { useAuth } from "@app/auth";
import { MuiButton } from "@causevest/ui-kit";
import { Box, LinearProgress } from "@mui/material";
import clsx from "clsx";

import { useSession } from "@contexts";

import { AppRoutes } from "@lib/types";

import { Envelope } from "./Envelope";
import { UserInfo } from "./UserInfo";
import classes from "./header.module.scss";
import { CampaignsIcon } from "./icons/Campaigns";
import { LeaderboardIcon } from "./icons/Leaderboard";

interface Props {
  setLogInOpen: () => void;
  setSignUpOpen: () => void;
  isMobile?: boolean;
  onClick?: () => void;
}

export const HeaderMenu: FC<Props> = ({ setLogInOpen, setSignUpOpen, isMobile, onClick }) => {
  const pathname = usePathname();
  const { isAuthenticated, isLoading } = useAuth();
  const { user } = useSession();

  return (
    <ul className={classes.menuList} onClick={onClick}>
      <li
        className={clsx(classes.menuList__item, {
          [classes._active]: pathname === AppRoutes.CAMPAIGNS,
        })}
      >
        <Link href={AppRoutes.CAMPAIGNS}>
          <CampaignsIcon />
          Campaigns
        </Link>
      </li>
      <li
        className={clsx(classes.menuList__item, {
          [classes._active]: pathname === AppRoutes.LEADERBOARD,
        })}
      >
        <Link href={AppRoutes.LEADERBOARD}>
          <LeaderboardIcon />
          Leaderboard
        </Link>
      </li>
      {isLoading ? (
        <li>
          <Box sx={{ width: "150px" }}>
            <LinearProgress />
          </Box>
        </li>
      ) : (
        <>
          {isAuthenticated && !isMobile ? (
            <>
              <li className={classes.divider__wrapper}>
                <Box className={classes.divider} />
              </li>
              <li>
                <UserInfo user={user} />
              </li>
              <li>
                <Envelope count={0} />
              </li>
            </>
          ) : (
            !isAuthenticated && (
              <>
                <li>
                  <MuiButton
                    className={clsx(classes.menuList__item, classes._logIn)}
                    variant="text"
                    disableRipple
                    onClick={setLogInOpen}
                  >
                    Log In
                  </MuiButton>
                </li>
                <li className={classes.divider__wrapper}>
                  <Box className={classes.divider} />
                </li>
                <li>
                  <MuiButton
                    className={classes.signUpBtn}
                    onClick={setSignUpOpen}
                    style={{ fontSize: "14px" }}
                  >
                    Sign Up
                  </MuiButton>
                </li>
              </>
            )
          )}
        </>
      )}
    </ul>
  );
};
