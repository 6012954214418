"use client";

import Link from "next/link";

import React, { FC } from "react";

import { useAppModalsContext, useAuth } from "@app/auth";
import { GradientButton, Icon, SecondaryButton } from "@causevest/ui-kit";
import { SocialBar } from "@components";
import { Box, Container, Stack, Typography } from "@mui/material";
import clsx from "clsx";

import { PLATFORM_SOCIAL } from "@lib/constants";
import { AppRoutes } from "@lib/types";

import classes from "./FirstScreen.module.scss";

interface Props {
  title: string;
  description: string;
  image: string;
  isCampaignsPage?: boolean;
}

export const FirstScreen: FC<Props> = ({ title, description, image, isCampaignsPage }) => {
  const { setLogInOpen } = useAppModalsContext();
  const { isAuthenticated } = useAuth();

  const onGetStarted = () => {
    if (isCampaignsPage) {
      window.scrollTo({ top: 100, behavior: "smooth" });
    } else {
      setLogInOpen();
    }
  };

  return (
    <Container className={classes.firstScreen} role="section">
      <Box className={classes.firstScreen__social}>
        <SocialBar social={PLATFORM_SOCIAL} />
      </Box>
      <Stack className={classes.inner}>
        <Typography variant="h1" className={classes.firstScreen__title}>
          {title}
        </Typography>
        <Typography className={classes.firstScreen__subtitle}>{description}</Typography>
        <Stack flexDirection="row" className={classes.firstScreen__buttons}>
          {!isAuthenticated && (
            <GradientButton className={classes.firstScreen__getStarted} onClick={onGetStarted}>
              Get Started
            </GradientButton>
          )}
          {!isCampaignsPage && (
            <Link href={AppRoutes.CAMPAIGNS}>
              <SecondaryButton className={classes.firstScreen__explore}>
                Explore Campaigns
              </SecondaryButton>
            </Link>
          )}
        </Stack>
      </Stack>
      <Icon
        src={image}
        alt="landing"
        width={320}
        height={180}
        style={isCampaignsPage ? { objectFit: "contain" } : {}}
        className={clsx(classes.firstScreen__pic, classes._small)}
      />
      <Icon
        src={image}
        alt="landing"
        width={520}
        height={300}
        style={isCampaignsPage ? { objectFit: "contain" } : {}}
        className={clsx(classes.firstScreen__pic, classes._medium)}
      />
      <Icon
        src={image}
        alt="landing"
        width={720}
        height={403}
        style={isCampaignsPage ? { objectFit: "contain" } : {}}
        className={clsx(classes.firstScreen__pic, classes._big)}
      />
    </Container>
  );
};
