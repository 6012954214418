import { FC, ReactElement, createContext, useCallback, useContext, useMemo, useState } from "react";

import { GradientButton } from "@causevest/ui-kit";
import { SxProps } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { AxiosError } from "axios";

import { proxyAPIGateway } from "@api";

import { useCommonDataContext, useSession } from "@contexts";

import { handleErrorToast } from "@lib/helpers";
import { Campaign, DonationBody, ErrorUnprocessable } from "@lib/types";

interface ReturnType {
  donationBody: Partial<DonationBody>;
  startDonation: ((body: Partial<DonationBody>) => Promise<void>) | (() => void);
  closeDonationScreens: () => void;
  hitSuccessModal: () => void;
  currentAmount: number;
  setLoading: (isLoading: boolean) => void;
  isLoading: boolean;
  step?: number;
}

interface Props {
  campaign: Partial<Campaign>;
  children: ReactElement[];
  initialAmount?: number;
  sx?: SxProps;
  className?: string;
}

const initialState = {
  donationBody: {},
  currentAmount: 0,
  isLoading: false,
};

const noopFunction = () => console.warn("Initialize Donation Context Provider first");

const DonationContext = createContext<ReturnType>({
  ...initialState,
  startDonation: noopFunction,
  closeDonationScreens: noopFunction,
  setLoading: noopFunction,
  hitSuccessModal: noopFunction,
});

export const useDonationContext = (): ReturnType => useContext(DonationContext);

export const DonationContextProvider: FC<Props> = ({
  campaign,
  children,
  initialAmount,
  sx,
  className,
}) => {
  const { user } = useSession();
  const { stripeKey } = useCommonDataContext();
  const stripePromise = useMemo(() => loadStripe(stripeKey), [stripeKey]);
  const [isLoading, setIsLoading] = useState(initialState.isLoading);
  const [step, setStep] = useState<number>();
  const startAmount =
    initialAmount ?? (campaign.goal ? +campaign.goal.target_amount : initialState.currentAmount);
  const [currentAmount, setCurrentAmount] = useState<number>(startAmount);
  const [clientSecret, setClientSecret] = useState();
  const [donationBody, setDonationBody] = useState<Partial<DonationBody>>({});

  const hitNextStep = () => {
    setStep((prevState) => (prevState ? prevState + 1 : 0));
  };

  const startDonation = useCallback(
    async ({ amount, donate_as, ...body }: Partial<DonationBody & { donate_as: string }>) => {
      try {
        setIsLoading(true);
        const { data } = await proxyAPIGateway.post("/donation/create", {
          amount,
          campaign_id: campaign.uuid,
          currency_id: campaign.goal?.currency.uuid,
          anonymous: !user,
          email: user?.email,
          first_name: user?.first_name,
          last_name: user?.last_name,
          postal_code: user?.address?.postal_code,
          country_id: user?.address?.country,
          ...body,
        });

        if (data.client_secret) {
          setDonationBody((prevState) => ({ ...prevState, ...body, amount }));
          setCurrentAmount((prevState) => (amount ? prevState + amount : prevState));
          setClientSecret(data.client_secret);
          hitNextStep();
        }
      } catch (err) {
        handleErrorToast((err as AxiosError).response?.data as ErrorUnprocessable);
      } finally {
        setIsLoading(false);
      }
    },
    [campaign.goal?.currency.uuid, campaign.uuid, user],
  );

  const closeDonationScreens = useCallback(() => {
    setDonationBody(initialState.donationBody);
    setStep(undefined);
    setCurrentAmount(initialState.currentAmount);
  }, []);

  const setLoading = useCallback((loading: boolean) => {
    setIsLoading(loading);
  }, []);

  const hitSuccessModal = useCallback(() => {
    setStep(2);
  }, []);

  return (
    <DonationContext.Provider
      value={{
        donationBody,
        startDonation,
        step,
        closeDonationScreens,
        hitSuccessModal,
        currentAmount,
        setLoading,
        isLoading,
      }}
    >
      <Elements key={clientSecret} options={{ clientSecret }} stripe={stripePromise}>
        <GradientButton
          sx={{ height: "35px", ...sx }}
          onClick={() => setStep(0)}
          className={className}
        >
          Donate Now
        </GradientButton>
        {children}
      </Elements>
    </DonationContext.Provider>
  );
};
