import { FC } from "react";

import { Icon } from "@causevest/ui-kit";
import { Box, Stack, Typography } from "@mui/material";

import { DonationEmitterBlock } from "@features/donation/DonationEmitterBlock";

import { getParsedDate, setCurrency } from "@lib/helpers";
import { Campaign } from "@lib/types";

import { Fundraisers } from "../../";
import { CampaignStatusInput } from "../CampaignStatusInput";
import parentClasses from "../RaiserInner.module.scss";
import classes from "./SubscriptionMainInfo.module.scss";

interface Props {
  campaign: Campaign;
}

export const SubscriptionMainInfo: FC<Props> = ({ campaign }) => {
  const revenue =
    `${setCurrency(campaign.goal?.currency?.iso4217 ?? "$")} ${campaign.goal?.target_amount ?? 0}`.trim();

  return (
    <>
      {!!campaign.image.length && (
        <Icon
          src={campaign.image}
          alt="campaign pic"
          style={{ objectFit: "cover" }}
          sizes="100%"
          className={classes.mainImg}
          fill
        />
      )}
      <Stack className={classes.wrapper}>
        <Stack className={classes.inner}>
          <Stack className={classes.aside}>
            <CampaignStatusInput
              uuid={campaign.uuid}
              status={campaign.status}
              label="Campaign Status"
            />
            <Stack className={classes.statistic}>
              <Box>
                <Typography className={classes.statistic__title}>Campaign Start Date</Typography>
                <Typography>{getParsedDate(campaign.cause.created_at, "-")}</Typography>
              </Box>
              <Box>
                <Typography className={classes.statistic__title}>Supporters</Typography>
                <Typography>{campaign.votes ?? 0}</Typography>
              </Box>
              <Box>
                <Typography className={classes.statistic__title}>Tiers</Typography>
                <Typography>{campaign.tiers.length ?? 0}</Typography>
              </Box>
              <Box>
                <Typography className={classes.statistic__title}>Revenue-to-date</Typography>
                <Typography>{revenue}</Typography>
              </Box>
            </Stack>
          </Stack>
          <Stack className={classes.info}>
            <Box>
              <Typography
                className={parentClasses.id}
              >{`Campaign ID: ${campaign.uuid}`}</Typography>
              <Typography variant="h1">{campaign.title}</Typography>
              <Typography className={parentClasses.description}>
                {campaign.short_description}
              </Typography>
            </Box>
            <Fundraisers campaign={campaign} />
          </Stack>
        </Stack>
        <DonationEmitterBlock campaign={campaign} />
      </Stack>
    </>
  );
};
