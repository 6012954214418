"use client";

import { FC } from "react";

import { TiersListModal } from "@app/campaigns/[id]";
import { GradientButton, useElementOpen } from "@causevest/ui-kit";

import { SuccessDonationModal } from "@features/donation/modals/SuccessDonationModal";

import { Campaign, User } from "@lib/types";

interface Props {
  campaign: Partial<Campaign>;
  buttonLabel?: string;
}

export const DonationEmitterBlock: FC<Props> = ({ campaign, buttonLabel = "Donate Now" }) => {
  const { isOpen: areTiersOpen, open: setTiersOpen, close: setTiersClose } = useElementOpen(false);
  const {
    isOpen: isSuccessOpen,
    open: setSuccessOpen,
    close: setSuccessClose,
  } = useElementOpen(false);

  return (
    <>
      <GradientButton sx={{ width: "170px", height: "35px" }} onClick={setTiersOpen}>
        {buttonLabel}
      </GradientButton>
      <TiersListModal
        isOpen={areTiersOpen}
        onClose={setTiersClose}
        tiers={campaign.tiers}
        current={0}
        owner={campaign.owner as Partial<User>}
        setSuccessOpen={setSuccessOpen}
      />
      <SuccessDonationModal campaign={campaign} isOpen={isSuccessOpen} onClose={setSuccessClose} />
    </>
  );
};
