"use client";

import { CloseIcon } from "next/dist/client/components/react-dev-overlay/internal/icons/CloseIcon";

import { FC, useState } from "react";
import { FieldValues } from "react-hook-form";
import { toast } from "react-toastify";

import { Icon, MuiButton, useElementOpen } from "@causevest/ui-kit";
import { ShareBar } from "@components";
import { Stack } from "@mui/material";
import { AxiosError } from "axios";
import clsx from "clsx";

import { threadAPI } from "@api";

import { Thread } from "@lib/types";

import { useUpdateCampaign } from "../../";
import { CreateCommentForm } from "./CreateCommentForm";
import { SubComment } from "./SubComment";
import classes from "./Thread.module.scss";
import { ThreadLikeBtn } from "./ThreadLikeBtn";

interface Props {
  thread: Thread;
}

export const ThreadActions: FC<Props> = ({ thread }) => {
  const { isOpen: isCommentsListOpen, toggleOpen: toggleCommentsListOpen } = useElementOpen(false);
  const { isOpen: isShareOpen, toggleOpen: toggleShareOpen } = useElementOpen(false);
  const {
    isOpen: isClaimOpen,
    toggleOpen: toggleClaimOpen,
    close: closeClaim,
  } = useElementOpen(false);

  const [isCommentsLoading, setIsCommentsLoading] = useState(false);
  const [isLikeLoading, setIsLikeLoading] = useState(false);
  const [isClaimLoading, setIsClaimLoading] = useState(false);
  const [persistedThread, setPersistedThread] = useState<Thread>(thread);

  const { updateCampaign: toggleThreadLike } = useUpdateCampaign(
    "post",
    "/threads/like/toggle",
    false,
  );

  const { updateCampaign: createComment } = useUpdateCampaign(
    "post",
    "/threads/comment/create",
    false,
  );

  const { updateCampaign: claimThread } = useUpdateCampaign(
    "post",
    "/threads/claim",
    "Report received",
  );

  const handleOptimisticUpdate = async (id: string) => {
    const updatedThread = await threadAPI.getThread(id);
    setPersistedThread(updatedThread);
  };

  const toggleLike = async () => {
    try {
      setIsLikeLoading(true);
      await toggleThreadLike({ thread: thread.id });
      await handleOptimisticUpdate(thread.id);
    } catch (err) {
      toast.error((err as AxiosError).message, {
        theme: "colored",
      });
    } finally {
      setIsLikeLoading(false);
    }
  };

  const onCreateComment = async ({ content }: FieldValues) => {
    try {
      setIsCommentsLoading(true);
      await createComment({ thread: thread.id, content });
      await handleOptimisticUpdate(thread.id);
    } catch (err) {
      toast.error((err as AxiosError).message, {
        theme: "colored",
      });
    } finally {
      setIsCommentsLoading(false);
    }
  };

  const handleSendClaim = async ({ content }: FieldValues) => {
    try {
      setIsClaimLoading(true);
      await claimThread({ thread: thread.id, reason: content });
      closeClaim();
    } catch (err) {
      toast.error((err as AxiosError).message, {
        theme: "colored",
      });
    } finally {
      setIsClaimLoading(false);
    }
  };

  return (
    <>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        className={classes.bottom}
      >
        <Stack flexDirection="row" alignItems="center" className={classes.bottom}>
          <ThreadLikeBtn
            likeCount={persistedThread.like_count}
            toggleLike={toggleLike}
            isLoading={isLikeLoading}
          />
          <MuiButton
            variant="text"
            className={classes.bottom__btn}
            sx={{ justifyContent: "flex-start" }}
            onClick={toggleShareOpen}
            disableRipple
          >
            <Icon
              src="/images/upload.svg"
              width={9}
              height={9}
              alt="upload"
              className={classes.bottom__icon}
            />
            <span>Share</span>
          </MuiButton>
          {persistedThread.comment_count > 0 ? (
            <MuiButton
              variant="text"
              className={classes.bottom__btn}
              onClick={toggleCommentsListOpen}
              disableRipple
            >
              <Icon
                src="/images/comments.svg"
                width={11}
                height={10}
                alt="comments"
                className={classes.bottom__icon}
              />
              <span>{`${persistedThread.comment_count} Comments`}</span>
            </MuiButton>
          ) : (
            <MuiButton
              variant="text"
              className={classes.bottom__btn}
              onClick={toggleCommentsListOpen}
              disableRipple
            >
              Leave comment
            </MuiButton>
          )}
        </Stack>
        <MuiButton
          variant="text"
          className={classes.bottom__btn}
          onClick={toggleClaimOpen}
          disableRipple
        >
          <Icon
            src="/images/report.svg"
            width={12}
            height={10}
            alt="report"
            className={classes.bottom__icon}
          />
          <span>Report this post</span>
        </MuiButton>
      </Stack>
      {isCommentsListOpen && (
        <Stack className={classes.subcomments}>
          {persistedThread.comments?.map((comment) => (
            <SubComment key={comment.id} comment={comment} />
          ))}
          <CreateCommentForm
            onSubmit={onCreateComment}
            onClose={toggleCommentsListOpen}
            isLoading={isCommentsLoading}
            placeholder="Enter comment here"
          />
        </Stack>
      )}
      {isShareOpen && (
        <Stack className={clsx(classes.subcomments, classes._share)}>
          <ShareBar url={process.env.ORIGIN_URL as string} title="Causevest" />
          <MuiButton variant="text" onClick={toggleShareOpen} className={classes.closeBtn}>
            <CloseIcon />
          </MuiButton>
        </Stack>
      )}
      {isClaimOpen && (
        <CreateCommentForm
          onSubmit={handleSendClaim}
          onClose={toggleClaimOpen}
          isLoading={isClaimLoading}
          placeholder="Enter your report here"
        />
      )}
    </>
  );
};
