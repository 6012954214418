import React, { FC } from "react";

import { useCampaignContext } from "@app/campaigns/[id]";
import { ModalActionsField, MuiDialog, Preloader } from "@causevest/ui-kit";
import { LoggedInSection } from "@components";
import { Box, Stack } from "@mui/material";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripePaymentElementOptions } from "@stripe/stripe-js";
import { AxiosError } from "axios";
import clsx from "clsx";

import { useDonationContext } from "@features/donation/DonationContext";
import DonationModalAside from "@features/donation/modals/DonationModalAside";
import classes from "@features/donation/modals/StepModal.module.scss";

import { useSession } from "@contexts";

import { handleErrorToast } from "@lib/helpers";
import { Campaign, ErrorUnprocessable } from "@lib/types";

interface Props {
  campaign: Partial<Campaign>;
  initialAmount?: number;
}

export const StepTwoModal: FC<Props> = ({ campaign, initialAmount }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { setPersistedCampaignData } = useCampaignContext();
  const { user } = useSession();
  const { closeDonationScreens, setLoading, hitSuccessModal, donationBody, step, isLoading } =
    useDonationContext();

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: "tabs",
  };

  const onPaymentSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    try {
      setLoading(true);

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.href,
          payment_method_data: {
            billing_details: {
              email: user?.email ?? donationBody.email,
              name:
                user?.display_name ?? `${donationBody.first_name} ${donationBody.last_name}`.trim(),
              address: {
                country: user?.address?.country?.uuid ?? donationBody.country_id,
                postal_code: user?.address?.postal_code ?? donationBody.postal_code,
              },
            },
          },
        },
        redirect: "if_required",
      });

      if (error) {
        if (error.type === "card_error" || error.type === "validation_error") {
          handleErrorToast(error.message || "An error occurred.");
        } else {
          handleErrorToast("An unexpected error occurred.");
        }
      } else {
        setPersistedCampaignData({
          goal: { ...campaign.goal, amount: initialAmount },
        } as unknown as Partial<Campaign>);
        hitSuccessModal();
      }
    } catch (err) {
      handleErrorToast((err as AxiosError).response?.data as ErrorUnprocessable);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MuiDialog
      isOpen={step === 1}
      handleClose={closeDonationScreens}
      title="Payment"
      titleActionBlock={<LoggedInSection onClose={closeDonationScreens} />}
      bottomContent={
        <ModalActionsField
          onClose={closeDonationScreens}
          onSubmit={onPaymentSubmit}
          submitBtnText="Donate"
        />
      }
    >
      <Stack className={classes.wrapper}>
        <Box className={classes.form}>
          {isLoading && (
            <Stack sx={{ width: "100%", height: "100%" }}>
              <Box sx={{ m: "auto" }}>
                <Preloader />
              </Box>
            </Stack>
          )}
          <Box className={clsx(classes.form__payment, { [classes._isLoading]: isLoading })}>
            <PaymentElement id="payment-element" options={paymentElementOptions} />
          </Box>
        </Box>
        <DonationModalAside campaign={campaign} />
      </Stack>
    </MuiDialog>
  );
};
