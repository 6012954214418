"use client";

import { FC } from "react";

import { Container, Stack } from "@mui/material";

import { Campaign } from "@lib/types";

import { CampaignFeed, CampaignProvider } from "../";
import { CampaignAside } from "./CampaignAside";
import { CampaignHeader } from "./CampaignHeader";
import classes from "./DonorView.module.scss";
import { SubscriptionAside } from "./SubscriptionAside";
import { SubscriptionHeader } from "./SubscriptionHeader";

interface Props {
  campaign: Campaign;
}

export const DonorView: FC<Props> = ({ campaign }) => (
  <CampaignProvider>
    {campaign.is_subscription ? (
      <SubscriptionHeader campaign={campaign} />
    ) : (
      <CampaignHeader campaign={campaign} />
    )}
    <Container>
      <Stack className={classes.feed}>
        <CampaignFeed campaign={campaign} />
        {campaign.is_subscription ? (
          <SubscriptionAside tiers={campaign.tiers} owner={campaign.owner} />
        ) : (
          <CampaignAside campaign={campaign} />
        )}
      </Stack>
    </Container>
  </CampaignProvider>
);
