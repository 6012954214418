"use client";

import { FC, ReactElement } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AppModalsProvider, AuthProvider } from "@app/auth";
import { Footer, Header } from "@components";
import { Box } from "@mui/material";
import { AppRouterCacheProvider } from "@mui/material-nextjs/v13-appRouter";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { GoogleOAuthProvider } from "@react-oauth/google";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { CommonDataProvider, SessionProvider } from "@contexts";

import classes from "@lib/styles/common.module.scss";
import theme from "@lib/theme";
import { CommonData } from "@lib/types";

// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(relativeTime);

interface Props extends CommonData {
  children: ReactElement | ReactElement[];
}

export const ClientOverlay: FC<Props> = ({ children, user, ...common }) => (
  <AppRouterCacheProvider options={{ enableCssLayer: true }}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SessionProvider user={user}>
        <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID as string}>
          <AuthProvider>
            <CommonDataProvider {...common}>
              <AppModalsProvider>
                <Header />
                <Box role="main" className={classes.pageWrapper}>
                  {children}
                </Box>
                <Footer />
              </AppModalsProvider>
            </CommonDataProvider>
          </AuthProvider>
        </GoogleOAuthProvider>
        <ToastContainer position="top-right" autoClose={5000} theme="light" />
      </SessionProvider>
    </ThemeProvider>
  </AppRouterCacheProvider>
);
