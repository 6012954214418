import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import {
  CurrencyInput,
  MuiDialog,
  MuiSelect,
  Preloader,
  Switcher,
  TextArea,
  TextInput,
} from "@causevest/ui-kit";
import { LoggedInSection } from "@components";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, MenuItem, Stack } from "@mui/material";
import * as yup from "yup";

import { useCommonDataContext, useSession } from "@contexts";

import { REQUIRED_MESSAGE } from "@lib/constants";
import common from "@lib/styles/common.module.scss";
import { Campaign, FormReturn } from "@lib/types";

import { useDonationContext } from "../DonationContext";
import DonationModalAside from "./DonationModalAside";
import classes from "./StepModal.module.scss";

interface Props {
  campaign: Partial<Campaign>;
  initialAmount?: number;
}

const schema = yup.object().shape({
  comment: yup.string().required(REQUIRED_MESSAGE),
  email: yup.string(),
  first_name: yup.string(),
  last_name: yup.string(),
  country: yup.string(),
  postal_code: yup.string(),
  donate_as: yup.string(),
  currency: yup.string(),
});

export default function StepOneModal({ campaign, initialAmount }: Props) {
  const { countries, currencies } = useCommonDataContext();
  const { user } = useSession();
  const { closeDonationScreens, startDonation, step, isLoading, donationBody } =
    useDonationContext();
  const minAmount = campaign.goal?.minimum_amount ?? 1;

  const methods = useForm({
    resolver: yupResolver(
      schema.shape({
        amount: yup
          .number()
          .min(minAmount, `Amount must be greater than or equal to ${minAmount}`)
          .required(REQUIRED_MESSAGE),
      }),
    ),
    defaultValues: {
      amount: minAmount,
      donate_as: "false",
      currency: currencies[0].uuid,
    },
  });

  const {
    watch,
    reset,
    formState: { errors },
  } = methods;

  const donateAs = donationBody.anonymous ? "true" : watch("donate_as");

  useEffect(() => {
    if (initialAmount) {
      reset({ amount: initialAmount });
    }
  }, [initialAmount]); // eslint-disable-line

  return (
    <MuiDialog
      isOpen={step === 0}
      handleClose={closeDonationScreens}
      methods={methods as unknown as FormReturn}
      title="Make a Donation"
      onSubmit={startDonation}
      submitBtnText="Next"
      titleActionBlock={<LoggedInSection onClose={closeDonationScreens} />}
    >
      <Stack className={classes.wrapper}>
        {isLoading ? (
          <Stack sx={{ width: "100%", height: "100%" }}>
            <Box sx={{ m: "auto" }}>
              <Preloader />
            </Box>
          </Stack>
        ) : (
          <Stack className={classes.form}>
            <CurrencyInput
              placeholder="ex: 100"
              name="amount"
              label="How much would you like to give?"
              error={errors.amount?.message}
              currencies={currencies}
              required
            />
            {!!user && (
              <Switcher name="donate_as" items={["Yourself", "Anonymous"]} label="Donate As" />
            )}
            {(!user || donateAs === "true") && (
              <>
                <TextInput
                  label="Email"
                  name="email"
                  className={common.input}
                  placeholder="Type your email here"
                />
                <TextInput
                  label="First Name"
                  name="first_name"
                  className={common.input}
                  placeholder="Type your first name here"
                />
                <TextInput
                  label="Last Name"
                  name="last_name"
                  className={common.input}
                  placeholder="Type your last name here"
                />
                <MuiSelect
                  name="country"
                  id="country"
                  label="Country of Residence"
                  placeholderValue="Select a country"
                  className={common.input}
                  fullWidth
                >
                  {countries.map((country) => (
                    <MenuItem key={country.uuid} value={country.uuid}>
                      {country.name}
                    </MenuItem>
                  ))}
                </MuiSelect>
                <TextInput
                  label="Postal Code"
                  name="postal_code"
                  className={common.input}
                  placeholder="Type your postal code here"
                />
              </>
            )}
            <TextArea
              label="Comment"
              name="comment"
              placeholder="Type your comment here"
              rows={3}
              errorMessage={errors.comment?.message}
              error={!!errors.comment}
              required
            />
          </Stack>
        )}
        <DonationModalAside campaign={campaign} />
      </Stack>
    </MuiDialog>
  );
}
